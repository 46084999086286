<template>
  <router-view />
</template>

<script>
import { event } from "vue-gtag";

export default {
  name: 'PublicLayout',
  setup() {
    const login = () => {
      event('login', { method: 'Google' });
    }
    return { login }
  },
  created() {
    document.body.classList.add("authentication");
  }
};
</script>

<style>
</style>
